// Third Party library
import React, { useState } from 'react';
import {
  Dropdown,
  DropdownItemProps,
  DropdownOnSearchChangeData,
  Header,
} from 'semantic-ui-react';
import { useDebouncedCallback } from 'use-debounce';
import { useNavigate } from 'react-router-dom';

// Models
import SequenceModel, {
  SequenceFolderInterface,
  SequenceInterface,
} from '../../../../models/sequence';

// Locales
import I18n from '../../../../i18n';

// Styles
import './customFilter.scss';

const CustomFilter = () => {
  const navigate = useNavigate();

  const [stateOptions, setStateOptions] = useState<DropdownItemProps[]>([]);

  const [loading, setLoading] = useState(false);

  const handleSearchChange = useDebouncedCallback(
    async (
      e: React.SyntheticEvent,
      { searchQuery }: DropdownOnSearchChangeData
    ) => {
      setLoading(true);

      const [folders, sequences] = await Promise.all([
        SequenceModel.getSequenceFolders(searchQuery),
        SequenceModel.getSequences(searchQuery),
      ]);

      const options = [
        ...folders.map((folder: SequenceFolderInterface, index) => ({
          key: folder._id + index,
          text: folder.name,
          value: folder._id,
          content: (
            <Header
              icon="folder"
              size='tiny'
              as="h5"
              content={folder.name}
              onClick={() => navigate(`/sequences?folderId=${folder._id}`)}
            />
          ),
        })),
        ...sequences.map((sequence: SequenceInterface, index) => ({
          key: sequence._id + index,
          text: sequence.title.ES,
          value: sequence._id,
          content: (
            <Header
              icon="file"
              size='tiny'
              as="h5"
              content={sequence.title.ES}
              onClick={() => navigate(`/sequences/edit/${sequence._id}`)}
            />
          ),
        })),
      ];

      setStateOptions(options);

      setLoading(false);
    },
    500
  );

  return (
    <div className="customFilterComponent">
      <Dropdown
        className="customFilterDropdown"
        placeholder={I18n.t('submenu.searchPlaceholder')}
        search
        selection
        options={stateOptions}
        icon="search"
        onSearchChange={handleSearchChange}
        loading={loading}
      />
    </div>
  );
};

export default CustomFilter;
