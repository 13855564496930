// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react';

// Redux
import { closeLoader, openLoader } from '../../../redux-store/loader';

// Models
import StudentModel, { USER_PRO_STATUS, StudentInterface } from '../../../models/student';

// Components
import AddStudentToGroup from './components/addToGroup';
import BottomNav from '../../components/bottomNav/BottomNav';
import CustomList, { ItemInterface } from '../../components/list';
import GenericModal, { ClosedModal, GenericModalProps } from '../../components/genericModal';
import InviteStudent from './components/inviteStudent';
import PanelNav from '../../components/nav/PanelNav';
import SendMessage from '../../components/sendMessage';
import Submenu from '../../components/nav/Submenu';
import ImageViewer from '../../components/imageViewer';
import TutorialStarter from '../../hooks/tutorials/TutorialStarter';

// Styled components
import { Wrapper } from '../../styled/wrappers';

// Locales
import I18n from '../../../i18n';

// Styles
import './index.scss';

// Interfaces
interface MapDispatchProps {
    openLoader: any;
    closeLoader: any;
    currentLicense: any;
    userSession: any;
};

interface StudentsProps extends MapDispatchProps {
    navigate?: any;
    search?: any
};

interface StudentListItem extends StudentInterface {
    selected?: boolean;
}

interface StudentsState {
    currentStudents: ItemInterface[];
    editMode: boolean;
    filteredStudents: StudentListItem[];
    filterValue: string;
    inviteModalOpened: boolean;
    modal: GenericModalProps;
    students: StudentInterface[];
    unlinkedStudents: ItemInterface[];
    studentImageSrc: string | null | undefined;
    tutorialSteps: any[],
    loading: boolean
};

// Tutorial constants
const TutorialSteps = [
    { element: '#tutorial-invite-student', popover: { title: I18n.t('tutorials.students.invite.title'), description: I18n.t('tutorials.students.invite.description') } },
    { element: '#tutorial-linked-students', popover: { title: I18n.t('tutorials.students.linked.title'), description: I18n.t('tutorials.students.linked.description') } },
    { element: '#tutorial-unlinked-students', popover: { title: I18n.t('tutorials.students.unlinked.title'), description: I18n.t('tutorials.students.unlinked.description') } },
]

class Students extends React.Component<StudentsProps, StudentsState> {

    constructor(props: StudentsProps) {

        super(props);

        this.state = {
            currentStudents: [],
            editMode: false,
            filteredStudents: [],
            filterValue: '',
            inviteModalOpened: false,
            modal: ClosedModal,
            students: [],
            unlinkedStudents: [],
            studentImageSrc: null,
            tutorialSteps: [],
            loading: false
        };

    }

    componentDidMount() {

        this.setState({ tutorialSteps: TutorialSteps });

        this.getData();

    }

    dataToList = (list: any[], selectable: boolean): ItemInterface[] => {

        const returnedList: ItemInterface[] = [];
        _.each(list, item => {

            returnedList.push({
                _id: item._id,
                isAvatar: true,
                selectable,
                selected: !!item.selected,
                pictureUrl: item.pictureUrl,
                header: (item.name || I18n.t('students.noName')).toUpperCase(),
                description: (item.surname || '').toUpperCase()
            });

        });
        return returnedList;

    };

    getData = async () => {

        this.props.openLoader();

        this.setState({ loading: true });

        const students = await StudentModel.getStudents();
        const filteredStudents = _.orderBy(students, ['name', 'surname'], 'asc');

        const currentStudents = this.dataToList(_.filter(filteredStudents, student => student.status === USER_PRO_STATUS.LINKED), true);
        const unlinkedStudents = this.dataToList(_.filter(filteredStudents, student => student.status === USER_PRO_STATUS.UNLINKED || student.status === USER_PRO_STATUS.SYSTEM_UNLINKED || student.status === USER_PRO_STATUS.PENDING_PRO), false);

        this.setState({ currentStudents, filteredStudents, students, unlinkedStudents, loading: false });

        this.props.closeLoader();

    };

    onFilter = (filterValue: string) => {

        let filteredStudents = [ ...this.state.students ];
        if (filterValue) {

            filteredStudents = _.filter(filteredStudents, student => (student.name || '').toUpperCase().includes(filterValue.toUpperCase()) || (student.surname || '').toUpperCase().includes(filterValue.toUpperCase()));

        }

        const currentStudents = this.dataToList(_.filter(filteredStudents, student => student.status === USER_PRO_STATUS.LINKED), true);
        const unlinkedStudents = this.dataToList(_.filter(filteredStudents, student => student.status === USER_PRO_STATUS.UNLINKED || student.status === USER_PRO_STATUS.SYSTEM_UNLINKED), false);

        this.setState({ currentStudents, filteredStudents, unlinkedStudents, filterValue })

    }

    onEdit = () => {

        let { currentStudents, editMode } = { ...this.state };

        editMode = !editMode;
        if (!editMode) {

            _.map(currentStudents, student => student.selected = false);

        }

        this.setState({ currentStudents, editMode });

    };

    onSelectStudent = (studentId: string, checked: boolean = false) => {

        const { filteredStudents } = this.state;
        _.map(filteredStudents, student => student._id === studentId && (student.selected = checked));

        const currentStudents = this.dataToList(_.filter(filteredStudents, student => student.status === USER_PRO_STATUS.LINKED), true);

        this.setState({ currentStudents, filteredStudents });

    }

    onSelectAll = (checked: boolean = false) => {

        const { filteredStudents } = this.state;
        _.map(filteredStudents, student => student.status === USER_PRO_STATUS.LINKED && (student.selected = checked));

        const currentStudents = this.dataToList(_.filter(filteredStudents, student => student.status === USER_PRO_STATUS.LINKED), true);

        this.setState({ currentStudents, filteredStudents });

    }

    onSendMessage = (student: StudentInterface | StudentInterface[]) => {

        this.setState({
            modal: {
                open: true,
                title: I18n.t('students.sendMessage'),
                renderBody: () => <SendMessage student={ student } onForceUpdate={ this.getData } onChangeModal={ (modal: GenericModalProps) => this.setState({ modal }) } />
            }
        });

    };

    onAddToGroup = (student: StudentInterface | StudentInterface[]) => {

        this.setState({
            modal: {
                open: true,
                title: I18n.t('students.addGroup'),
                renderBody: () => <AddStudentToGroup onChangeModal={ (modal: GenericModalProps) => this.setState({ modal }) } onForceUpdate={ () => this.getData() } student={ student } />
            }
        });

    };

    getAllSelected = (): StudentInterface[] => {

        const { currentStudents, filteredStudents } = this.state;
        const students: StudentInterface[] = [];

        _.each(currentStudents, student => {

            if (student.selected) {

                const fullStudent = _.find(filteredStudents, item => item._id === student._id);
                !!fullStudent && students.push(fullStudent);

            }

        });

        return students;

    };

    closeModal = () => {
        this.setState({ modal: ClosedModal });
    }

    render() {

        const { currentStudents, editMode, filterValue, inviteModalOpened, modal, unlinkedStudents, studentImageSrc, tutorialSteps, loading } = this.state;
        const { currentLicense, navigate, userSession } = this.props;

        const allSelected = _.size(currentStudents) > 0 && !_.find(currentStudents, student => !student.selected);
        const anySelected = !!_.find(currentStudents, student => student.selected);

        return (
            <Wrapper id='tutorial-students-view'>
                <InviteStudent
                    key={ '' + inviteModalOpened }
                    open={ inviteModalOpened }
                    onClose={ () => this.setState({ inviteModalOpened: false }) }
                    currentStudents={ _.size(currentStudents) }
                    totalStudents={ currentLicense.maxStudents }
                    licenseType={ currentLicense.name }
                    userSession={ userSession }
                />
                <GenericModal
                    open={ modal.open }
                    title={ modal.title }
                    renderBody={ modal.renderBody }
                    renderActions={ modal.renderActions }
                    onClose={ this.closeModal }
                />
                <PanelNav active='students' />
                <Container style={{ paddingTop: '20px' }}>
                    <Submenu
                        allSelected={ allSelected }
                        anySelected={ anySelected }
                        contentEditable={ true }
                        editFn={ this.onEdit }
                        editMode={ editMode }
                        filterFn={ this.onFilter }
                        filterValue={ filterValue }
                        groupActions={ [
                            { icon: 'mail outline', label: I18n.t('students.sendMessage'), callback: () => this.onSendMessage(this.getAllSelected()) },
                            { icon: 'group', label: I18n.t('students.addGroup'), callback: () => this.onAddToGroup(this.getAllSelected()) }
                        ] }
                        mainAction={{id: 'tutorial-invite-student', title: I18n.t('students.invite'), callback: () => this.setState({ inviteModalOpened: true }) }}
                        selectAllFn={ this.onSelectAll }
                        title={ I18n.t('menu.students') }
                    />
                    <div className='inner'>
                        <div className='p-flex'>
                            <div className='p-content'>
                                <div className='a-wrapper'>
                                    <div className={ `a-f-wrapper ${ editMode && anySelected ? 'editMode' : '' }` }>
                                        <div className='listDivision'>
                                            <span id='tutorial-linked-students'>{ I18n.t('students.linked') + ' ' + _.size(currentStudents) + '/' + currentLicense.maxStudents }</span>
                                        </div>
                                        <CustomList
                                            editMode={ editMode }
                                            items={ currentStudents }
                                            onClickItemFn={ (item: ItemInterface) => navigate(`/students/edit/${ item._id }`) }
                                            onClickItemSpanFn={ (item: ItemInterface)=>  this.setState({ studentImageSrc: item.pictureUrl }) }
                                            onSelectItemFn={ this.onSelectStudent }
                                        />
                                        <div className='listDivision'>
                                            <span id='tutorial-unlinked-students'>{ I18n.t('students.unlinked') + ' ' + _.size(unlinkedStudents) }</span>
                                        </div>
                                        <CustomList
                                            items={ unlinkedStudents }
                                            onClickItemFn={ (item: ItemInterface) => navigate(`/students/edit/${ item._id }`) }
                                            onClickItemSpanFn={ (item: ItemInterface)=>  this.setState({ studentImageSrc: item.pictureUrl }) }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <BottomNav
                    active='students'
                    anySelected={ anySelected }
                    editMode={ editMode }
                    groupActions={ [
                        { icon: 'mail outline', label: I18n.t('students.sendMessage'), callback: () => this.onSendMessage(this.getAllSelected()) },
                        { icon: 'group', label: I18n.t('students.addGroup'), callback: () => this.onAddToGroup(this.getAllSelected()) }
                    ] }
                />
                <ImageViewer open={ studentImageSrc !== null } title={ I18n.t('students.picture') } src={ studentImageSrc } onClose={ () => this.setState({ studentImageSrc: null }) } />
                {
                    !loading && tutorialSteps && <TutorialStarter name='students' steps={ tutorialSteps } />
                }
            </Wrapper>
        );

    }

}

const mapStateToProps = (state: any) => {
    return { currentLicense: _.get(state, 'license.currentLicense'), userSession: _.get(state, 'auth.userSession') };
};

export default connect(mapStateToProps, { closeLoader, openLoader })(Students);