// Third party libraries
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { closeLoader, openLoader } from '../../../../../redux-store/loader';
import { useDispatch } from 'react-redux';

// Components
import GenericModal, { ClosedModal } from '../../../../components/genericModal';
import OButton from '../../../../styled/button';
import SequenceFoldersBody from './SequenceFoldersBody';

// Models
import SequenceModel, {
  SequenceInterface,
} from '../../../../../models/sequence';

// Locales
import I18n from '../../../../../i18n';

type Props = {
  open: boolean;
  sequence?: SequenceInterface;
  onClose: () => void;
  onSuccess: () => void;
};

const SequenceFoldersModal = ({
  open,
  sequence,
  onClose,
  onSuccess,
}: Props) => {
  const dispatch = useDispatch();

  const [modal, setModal] = useState(ClosedModal);

  const [folderId, setFolderId] = useState('none');

  const closeModal = () => setModal(ClosedModal);

  const moveSequence = useCallback(async () => {
    // TODO: Move sequence
    if (!folderId || !sequence || !sequence._id) return;

    closeModal();

    onClose();

    dispatch(openLoader());

    try {
      const updatedSequence = _.cloneDeep(sequence);

      updatedSequence.folderId = folderId;

      await SequenceModel.updateSequence(sequence._id, updatedSequence);
      onSuccess();
    } catch (error) {
      setModal({
        open: true,
        title: I18n.t('buttons.actions.error'),
        size: 'large',
        renderBody: () => <span>{I18n.t('messager.errorRecovery')}</span>,
        renderActions: () => (
          <OButton type="button" $color="white" $terciary onClick={onClose}>
            <span>{I18n.t('buttons.actions.understood')}</span>
          </OButton>
        ),
        onClose,
      });
    }

    dispatch(closeLoader());
  }, [dispatch, folderId, onClose, onSuccess, sequence]);

  const renderBody = useCallback(() => {
    return (
      <SequenceFoldersBody
        selectedFolderId={folderId}
        onSelectFolder={(folderId) => setFolderId(folderId)}
      />
    );
  }, [folderId]);

  const renderActions = useCallback(
    () => (
      <>
        <OButton type="button" $color="black" $primary onClick={onClose}>
          <span>{I18n.t('buttons.actions.cancel')}</span>
        </OButton>
        <OButton
          type="button"
          $color="white"
          $terciary
          disabled={!folderId}
          title={
            folderId === 'none'
              ? I18n.t('sequences.moveSequenceToRoot')
              : undefined
          }
          onClick={moveSequence}
        >
          <span>{I18n.t('buttons.actions.move')}</span>
        </OButton>
      </>
    ),
    [folderId, moveSequence, onClose]
  );

  useEffect(() => {
    if (open) {
      setModal({
        open: true,
        title: I18n.t('sequences.moveSequenceWithTitle', {
          title: sequence?.title?.ES,
        }),
        renderBody,
        renderActions,
        onClose,
      });
    } else {
      setModal(ClosedModal);
    }
  }, [onClose, open, renderActions, renderBody, sequence?.title?.ES]);

  if (!sequence) return null;

  return (
    <GenericModal
      open={modal.open}
      title={modal.title}
      renderBody={modal.renderBody}
      renderActions={modal.renderActions}
      onClose={modal.onClose}
    />
  );
};

export default SequenceFoldersModal;
