// Third party libraries
import { Checkbox, Icon, List } from 'semantic-ui-react';

// Models
import { SequenceFolderInterface } from '../../../../../models/sequence';

// Components
import OButton from '../../../../styled/button';

// Styles
import './index.scss';

// Locales
import I18n from '../../../../../i18n';

type Props = {
  folder: SequenceFolderInterface;
  isSelected?: boolean;
  onShowFolder: (folderId: string) => void;
  onSelectFolder: (folderId: string) => void;
};

const SequenceFolderItem = ({
  folder,
  isSelected = false,
  onShowFolder,
  onSelectFolder,
}: Props) => {
  return (
    <List.Item
      className="sequenceFolderItem"
      icon
      active={isSelected}
      onClick={() => onSelectFolder(folder._id)}
    >
      <List.Content className='sequenceFolderItemContent'>
        <Checkbox checked={ isSelected } onChange={() => onSelectFolder(folder._id)}/>
        <Icon name="folder" size="small" />
        <span>{folder.name}</span>
        <OButton
          $primary
          title={I18n.t('buttons.actions.viewContent')}
          onClick={() => onShowFolder(folder._id)}
          style={{ padding: 0, cursor: 'pointer' }}
        >
          <Icon circular name="chevron right" size="small" color="black" />
        </OButton>
      </List.Content>
    </List.Item>
  );
};

export default SequenceFolderItem;
