// Third party libraries
import _ from 'lodash';
import Moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react';

// Redux
import { closeLoader, openLoader } from '../../../../redux-store/loader';

// Models
import GroupModel from '../../../../models/group';
import SequenceModel, { SequenceInterface } from '../../../../models/sequence';
import StudentModel, { EmptyMatrix, EmptyStudent, MatrixInterface, StudentInterface, USER_PRO_STATUS } from '../../../../models/student';

// Components
import AddSequence from './components/addSequence';
import AddStudentToGroup from '../components/addToGroup';
import AssignedList from '../../../components/assignedList';
import BiometrixMatrix from './components/biometricMatrix';
import BottomNav from '../../../components/bottomNav/BottomNav';
import GenericModal, { ClosedModal, GenericModalProps } from '../../../components/genericModal';
import { ItemInterface } from '../../../components/list';
import MainInfo from './components/mainInfo';
import Messages from './components/messages';
import Notes from './components/notes';
import PanelNav from '../../../components/nav/PanelNav';
import PracticeInfo from './components/practiceInfo';
import SecondarySubmenu, { BreadcrumbsInterface } from '../../../components/nav/SecondarySubmenu';
import SendMessage from '../../../components/sendMessage';
import StudentInfo from './components/studentInfo';
import TrackingOptions from './components/trackingOptions';

// Styled components
import { Wrapper } from '../../../styled/wrappers';
import OButton from '../../../styled/button';

// Utils
import HooksService from '../../../../utils/HooksService';

// Locales
import I18n from '../../../../i18n';

// Styles
import './index.scss';

// Interfaces
interface MapDispatchProps {
    openLoader: any;
    closeLoader: any;
};

interface StudentEditProps extends MapDispatchProps {
    handleSubmit?: any;
    invalid?: boolean;
    navigate?: any;
    dispatch?: any;
    router?: any;
};

interface StudentEditState {
    assignedGroupList: ItemInterface[];
    assignedSequenceList: ItemInterface[];
    studentId: string;
    student: StudentInterface;
    breadcrumbs: BreadcrumbsInterface[];
    modal: GenericModalProps;
    sequences: SequenceInterface[];
    lastPracticeDate: string;
    periodStartedOnDate: string;
    practiceHoursTotal: string;
    teacherPractices: string;
    otherPractices: string;
    matrix: MatrixInterface;
};

class StudentEdit extends React.Component<StudentEditProps, StudentEditState> {

    constructor(props: StudentEditProps) {

        super(props);

        this.state = {
            assignedGroupList: [],
            assignedSequenceList: [],
            studentId: this.props.router.params.studentId,
            student: _.cloneDeep(EmptyStudent),
            breadcrumbs: [],
            modal: ClosedModal,
            sequences: [],
            lastPracticeDate: '',
            periodStartedOnDate: '',
            practiceHoursTotal: '',
            teacherPractices: '',
            otherPractices: '',
            matrix: _.cloneDeep(EmptyMatrix)
        };

    }

    componentDidMount() {

        this.getData();

    }

    getSequencePeriod = (startOn?: string, endOn?: string) => {

        let periodText = _.isEmpty(startOn) ? '' : I18n.t('sequences.from') + Moment(startOn).format('DD/MM/YYYY');
        periodText += (_.isEmpty(startOn) || _.isEmpty(endOn)) ? '' : ' - ';
        periodText += _.isEmpty(endOn) ? '' : I18n.t('sequences.to') + Moment(endOn).format('DD/MM/YYYY');
        return periodText;

    }

    getData = async () => {

        const { openLoader, closeLoader } = this.props;

        openLoader();

        const { studentId } = this.state;

        const student = await StudentModel.getStudent('' + studentId);

        const breadcrumbs = [{
            key: 0,
            label: I18n.t('menu.students'),
            goTo: '/students'
        },
        {
            key: 1,
            label: !!student.name && !!student.surname ? student.name + ' ' + student.surname : I18n.t('students.noName'),
            goTo: ''
        }];

        const groups = await GroupModel.getGroups();
        const assignedGroupList: ItemInterface[] = [];
        _.each(student.groups, groupId => {

            const group = _.find(groups, group => group._id === groupId);
            assignedGroupList.push({
                _id: groupId,
                selectable: false,
                selected: false,
                pictureUrl: group?.imageUrl,
                header: group?.name || '',
                description: group?.studentsCount + ' ' + I18n.t('menu.students')
            });

        });

        const matrix = await StudentModel.getStudentBiometricMatrix('' + studentId);

        const studentSequences = await StudentModel.getStudentSequences('' + studentId);
        const sequences = await SequenceModel.getSequences();
        const assignedSequenceList: ItemInterface[] = [];
        _.each(studentSequences, studentSequence => {

            const sequence = _.find(sequences, proSequence => proSequence._id === studentSequence.sequenceId);
            assignedSequenceList.push({
                _id: '' + sequence?._id,
                selectable: false,
                selected: false,
                pictureUrl: sequence?.thumbUrl,
                header: sequence?.title.ES || '',
                description: this.getSequencePeriod(studentSequence.startOn, studentSequence.endOn)
            });

        });

        const studentSessions = await StudentModel.getStudentSessions('' + studentId, matrix.updated);
        const proSequences = await SequenceModel.getSequences();

        let lastPracticeDate = '';
        const periodStartedOnDate = Moment(_.isEmpty(matrix.updated) ? student.createdAt : matrix.updated).format('DD/MM/YYYY - HH:mm');
        let practiceSeconds = 0;
        let proPracticeSeconds = 0;
        _.each(studentSessions, session => {

            if (lastPracticeDate === '' || Moment(session.startDate).isAfter(Moment(lastPracticeDate))) {

                lastPracticeDate = Moment(session.startDate).format();

            }

            practiceSeconds += session.timeElapsed;

            if(!!_.find(proSequences, proSequence => proSequence._id === session.sessionId)) {

                proPracticeSeconds += session.timeElapsed;

            }

        });

        const teacherPractices: string = (practiceSeconds > 0 ? _.round(proPracticeSeconds * 100 / practiceSeconds) : 0) + '%';
        const otherPractices: string = (practiceSeconds > 0 ? _.round(100 - (proPracticeSeconds * 100 / practiceSeconds)) : 0) + '%';

        lastPracticeDate = lastPracticeDate === '' ? '' : Moment(lastPracticeDate).format('DD/MM/YYYY - HH:mm');

        const practiceHoursTotal = _.round(practiceSeconds / 60 / 60, 2) + 'h';

        this.setState({ assignedGroupList, assignedSequenceList, breadcrumbs, lastPracticeDate, matrix, otherPractices, periodStartedOnDate, practiceHoursTotal, sequences, student, teacherPractices });

        closeLoader();

    };

    onSendMessage = () => {

        const { student } = this.state;

        this.setState({
            modal: {
                open: true,
                title: I18n.t('students.sendMessage'),
                renderBody: () => <SendMessage
                    student={ student }
                    onForceUpdate={ this.getData }
                    onChangeModal={ (modal: GenericModalProps) => this.setState({ modal }) }
                />
            }
        });

    };

    onAddToGroup = () => {

            const { student } = this.state;

        this.setState({
            modal: {
                open: true,
                title: I18n.t('students.addGroup'),
                renderBody: () => <AddStudentToGroup
                    onChangeModal={ (modal: GenericModalProps) => this.setState({ modal }) }
                    onForceUpdate={ () => this.getData() }
                    student={ student }
                />
            }
        });

    };

    onAssignSequence = () => {

        const { assignedSequenceList, studentId, sequences } = this.state;

        const unlinkedSequences: SequenceInterface[] = [];

        _.each(sequences, sequence => {

            if (!!!_.find(assignedSequenceList, item => item._id === sequence._id)) {

                unlinkedSequences.push(sequence);

            }

        });

        this.setState({
            modal: {
                open: true,
                title: I18n.t('students.addSequence'),
                renderBody: () => <AddSequence
                    studentId={ '' + studentId }
                    onChangeModal={ (modal: GenericModalProps) => this.setState({ modal }) }
                    onForceUpdate={ () => this.getData() }
                    sequences={ unlinkedSequences }
                />
            }
        });

    };

    onRemoveSequence = async (item: ItemInterface) => {

        this.props.openLoader();

        try {

            await StudentModel.unassignSequence('' + this.state.studentId, item._id);
            this.getData();

        } catch (e) {

            this.props.closeLoader();
            this.setState({ modal: {
                open: true,
                title: I18n.t('buttons.actions.error'),
                renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                renderActions: () => <OButton type='button' $color='white' $terciary onClick={ this.closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
            }});

        }

    };

    onRemoveStudent = async (item: ItemInterface) => {

        this.props.openLoader();

        try {

            await StudentModel.unassignGroup('' + this.state.studentId, item._id);
            this.getData();

        } catch (e) {

            this.props.closeLoader();
            this.setState({ modal: {
                open: true,
                title: I18n.t('buttons.actions.error'),
                renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                renderActions: () => <OButton type='button' $color='white' $terciary onClick={ this.closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
            }});

        }

    };

    goBack = () => this.props.navigate('/students');

    closeModal = () => this.setState({ modal: ClosedModal });

    render() {

        const { assignedGroupList, assignedSequenceList, breadcrumbs, student, modal, lastPracticeDate, periodStartedOnDate, practiceHoursTotal, teacherPractices, otherPractices, matrix } = this.state;

        return (_.isEqual(student, EmptyStudent) ? '' :
            <Wrapper>
                <GenericModal
                    open={ modal.open }
                    title={ modal.title }
                    renderBody={ modal.renderBody }
                    renderActions={ modal.renderActions }
                    onClose={ this.closeModal }
                />
                <PanelNav active='students' />
                <Container style={{ paddingTop: '20px' }}>
                    <SecondarySubmenu
                        title={ I18n.t('students.studentInfo') }
                        mainFn={{ callback: this.goBack, icon: 'angle left', label: I18n.t('buttons.actions.goBack') }}
                        breadcrumbs={ breadcrumbs }
                        secondaryFn={{ callback: this.onSendMessage, icon: 'mail outline' }}
                    />
                    <div className='inner'>
                        <div className='p-flex'>
                            <div className='p-content'>
                                <div className='a-wrapper'>
                                    <div className='a-f-wrapper'>
                                        <MainInfo
                                            student={ student }
                                            onAddToGroup={ this.onAddToGroup }
                                            onAssignSequence={ this.onAssignSequence }
                                            onChangeModal={ (modal: GenericModalProps) => this.setState({ modal }) }
                                            onSendMessage={ this.onSendMessage }
                                            onGetData={ this.getData }
                                        />
                                        { student?.status !== USER_PRO_STATUS.LINKED ? '' :
                                            <TrackingOptions
                                                student={ student }
                                                onChangeModal={ (modal: GenericModalProps) => this.setState({ modal }) }
                                                onGetData={ this.getData }
                                            />
                                        }
                                        <BiometrixMatrix
                                            gender={ student?.gender }
                                            matrix={ matrix }
                                            studentId={ student._id }
                                            updatedAt={ matrix.updated }
                                        />
                                        <PracticeInfo
                                            lastPracticeDate={ lastPracticeDate }
                                            periodStartedOnDate={ periodStartedOnDate }
                                            practiceHoursTotal={ practiceHoursTotal }
                                            teacherPractices={ teacherPractices }
                                            otherPractices={ otherPractices }
                                        />
                                        { student?.status !== USER_PRO_STATUS.LINKED ? '' :
                                            <>
                                                <AssignedList
                                                    title={ I18n.t('students.assignedSequences') }
                                                    items={ assignedSequenceList }
                                                    onClickItemFn={ (item: ItemInterface) => this.props.navigate('/sequences/edit/' + item._id) }
                                                    onAddNewItemFn={ this.onAssignSequence }
                                                    onAddNewItemLabel={ I18n.t('students.addSequence') }
                                                    itemFns={[{ icon: 'trash alternate outline', callback: this.onRemoveSequence }]}
                                                />
                                                <AssignedList
                                                    title={ I18n.t('students.assignedGroups') }
                                                    items={ assignedGroupList }
                                                    onClickItemFn={ (item: ItemInterface) => this.props.navigate('/groups/edit/' + item._id) }
                                                    onAddNewItemFn={ this.onAddToGroup }
                                                    onAddNewItemLabel={ I18n.t('students.addToOtherGroup') }
                                                    itemFns={[{ icon: 'trash alternate outline', callback: this.onRemoveStudent }]}
                                                />
                                            </>
                                        }
                                        <StudentInfo
                                            student={ student }
                                        />
                                        <Notes
                                            student={ student }
                                            onForceUpdate={ this.getData }
                                            onChangeModal={ (modal: GenericModalProps) => this.setState({ modal }) }
                                        />
                                        <Messages
                                            student={ student }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <BottomNav active='students' />
            </Wrapper>
        );

    }

}

export default HooksService.withRouter(connect(null, { openLoader, closeLoader })(StudentEdit));