import _ from 'lodash';

import AxiosService from '../utils/AxiosService';

import Config from '../config';

export enum USER_PRO_STATUS {
    DENIED_BY_PRO = 'denied-by-pro',
    LINKED = 'linked',
    PENDING_PRO = 'pending-pro',
    PENDING_USER = 'pending-user',
    STUDENT_UNLINKED = 'student-unlinked',
    SYSTEM_UNLINKED = 'system-unlinked',
    UNLINKED = 'unlinked'
};

export enum SEX {
    MALE = 1,
    FEMALE = 0
};

interface ProInterface {
    observations: string;
    objective: string;
    injuries: string[];
    updatedAt: string;
};

export interface MatrixInterface {
    abductionAductionIndicator: number;
    biometricMatrix: {
        front: any;
        rear: any;
    };
    bodyBalanceLevel: number;
    bodyPowerLevel: number;
    created: string;
    elasticityAverage: number;
    flexoextensionIndicator: number;
    isClosed: boolean;
    lateralizationIndicator: number;
    muscularTrainIndicator: number;
    progress: number;
    sequence: string;
    toneAverage: number;
    updated: string;
    userId: string;
    _id: string;
};

export const EmptyMatrix = {
    abductionAductionIndicator: 0,
    biometricMatrix: {
        front: {},
        rear: {}
    },
    bodyBalanceLevel: 0,
    bodyPowerLevel: 0,
    created: '',
    elasticityAverage: 0,
    flexoextensionIndicator: 0,
    isClosed: false,
    lateralizationIndicator: 0,
    muscularTrainIndicator: 0,
    progress: 0,
    sequence: '',
    toneAverage: 0,
    updated: '',
    userId: '',
    _id: ''
};

export interface StudentInterface {
    _id: string;
    createdAt: string;
    customTracking: boolean;
    gender: SEX;
    groups: string[];
    isPremium: boolean;
    messages: {
        createdAt: string;
        text: string;
    }[];
    name: string;
    notes: {
        createdAt: string;
        text: string;
    }[];
    pictureUrl: string;
    pro: ProInterface;
    role: string[];
    status: USER_PRO_STATUS;
    surname: string;
    updatedAt: string;
};

export const EmptyStudent = {
    _id: '',
    createdAt: '',
    customTracking: false,
    gender: SEX.FEMALE,
    groups: [],
    isPremium: false,
    name: '',
    messages: [],
    notes: [],
    pictureUrl: '',
    pro: {
        observations: '',
        objective: '',
        injuries: [],
        updatedAt:  ''
    },
    role: [],
    status: USER_PRO_STATUS.LINKED,
    surname: '',
    updatedAt: ''
};

export interface StudentSessionInterface {
    _id: string;
    sessionId: string;
    startDate: string;
    endDate?: string;
    status: string;
    userId: string;
    timeElapsed: number;
};

export interface StudentSequenceInterface {
    _id: string;
    createdAt: string;
    createdBy: string;
    sequenceId: string;
    userId: string;
    startOn?: string;
    endOn?: string;
};

const assignToGroup = async (id: string, groupId: string): Promise<void> => _.get(await AxiosService.put(Config.server.api.studentApi + 'pro/user/' + id + '/group/' + groupId, {}, true), 'data', {});
const multipleAssignToGroup = async (studentIds: string[], groupId: string): Promise<void> => _.get(await AxiosService.put(Config.server.api.studentApi + 'pro/user/group/' + groupId + '/multiple', studentIds, true), 'data', []);
const assignToSequence = async (id: string, sequenceId: string, startOn: string | undefined, endOn: string | undefined): Promise<void> => _.get(await AxiosService.post(Config.server.api.sessionsApi + 'pro/user/' + id + '/sequence/' + sequenceId, { startOn, endOn }, true), 'data', {});
const getStudent = async (id: string): Promise<StudentInterface> => _.get(await AxiosService.get(Config.server.api.studentApi + 'pro/user/' + id, true), 'data', {});
const getStudents = async (): Promise<StudentInterface[]> => _.get(await AxiosService.get(Config.server.api.studentApi + 'pro/user/list', true), 'data', []);
const getStudentBiometricMatrix = async (id: string): Promise<MatrixInterface> => _.get(await AxiosService.get(Config.server.api.biometricMatrixApi + `pro/user/${ id }`, true), 'data', {});
const getStudentSequences = async (id: string): Promise<StudentSequenceInterface[]> => _.get(await AxiosService.get(Config.server.api.sessionsApi + 'pro/user/' + id + '/sequence', true), 'data', []);
const getStudentSessions = async (id: string, dateFrom: string): Promise<StudentSessionInterface[]> => _.get(await AxiosService.get(Config.server.api.sessionsApi + 'pro/user/' + id + '/sessions/' + dateFrom, true), 'data', {});
const linkOrUnlinkStudent = async (id: string, status: USER_PRO_STATUS, reason: string | undefined): Promise<void> => _.get(await AxiosService.put(Config.server.api.studentApi + 'pro/user/' + id + '/link', { status, reason }, true), 'data', {});
const unassignGroup = async (id: string, groupId: string): Promise<void> => _.get(await AxiosService.deleting(Config.server.api.studentApi + 'pro/user/' + id + '/group/' + groupId, true), 'data', {});
const unassignSequence = async (id: string, sequenceId: string): Promise<void> => _.get(await AxiosService.deleting(Config.server.api.sessionsApi + 'pro/user/' + id + '/sequence/' + sequenceId, true), 'data', {});
const updateCustomTracking = async (id: string, checked: boolean): Promise<void> => _.get(await AxiosService.put(Config.server.api.studentApi + 'pro/user/' + id + '/tracking/' + checked, {}, true), 'data', {});
const updateStudent = async (id: string, message: string = '', note: string = ''): Promise<void> => _.get(await AxiosService.put(Config.server.api.studentApi + 'pro/user/' + id, { message, note }, true), 'data', {});

const exports = {
    assignToGroup,
    multipleAssignToGroup,
    assignToSequence,
    getStudent,
    getStudents,
    getStudentBiometricMatrix,
    getStudentSequences,
    getStudentSessions,
    linkOrUnlinkStudent,
    unassignGroup,
    unassignSequence,
    updateCustomTracking,
    updateStudent
};

export default exports;