// Third party libraries
import _ from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

// Models
import SequenceModel from '../../../../models/sequence';
import { SequenceInterface } from '../../../../models/sequence';

// Components
import { Breadcrumb } from 'semantic-ui-react';

// Locales
import I18n from '../../../../i18n';

// Interfaces
interface BreadcrumbInterface {
  _id: string;
  name: string;
}

type Props = {
  sequence?: SequenceInterface;
};

const SequenceBreadcrumb = ({ sequence }: Props) => {
  const navigate = useNavigate();

  const [urlSearchParams] = useSearchParams();

  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbInterface[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const folderId = urlSearchParams.get('folderId');

      if (sequence?.folderId) {
        setIsLoading(true);
        const breadcrumbs = await SequenceModel.getSequenceFolderPath(
          sequence.folderId
        );

        setBreadcrumbs(breadcrumbs);
        setIsLoading(false);
      } else if (folderId) {
        setIsLoading(true);
        const breadcrumbs = await SequenceModel.getSequenceFolderPath(folderId);

        setBreadcrumbs(breadcrumbs);
        setIsLoading(false);
      }
    };

    getData();
  }, [sequence, sequence?.folderId, urlSearchParams]);

  return (
    <Breadcrumb style={{ marginBottom: '1rem' }}>
      <Breadcrumb.Section link>
        <span onClick={() => navigate(`/sequences`)}>
          {I18n.t('menu.sequences')}
        </span>
      </Breadcrumb.Section>
      <Breadcrumb.Divider />
      {isLoading ? (
        <React.Fragment>
          <Breadcrumb.Section>
            <span>...</span>
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
        </React.Fragment>
      ) : (
        _.map(breadcrumbs, (breadcrumb, index) => {
          return (
            <React.Fragment key={index}>
              <Breadcrumb.Section link>
                {!_.isEmpty(breadcrumb) ? (
                  <span
                    onClick={() =>
                      navigate(`/sequences?folderId=${breadcrumb._id}`)
                    }
                  >
                    {breadcrumb.name}
                  </span>
                ) : (
                  breadcrumb.name
                )}
              </Breadcrumb.Section>
              <Breadcrumb.Divider />
            </React.Fragment>
          );
        })
      )}
      {sequence ? (
        <Breadcrumb.Section active>
          <span>{sequence.title.ES}</span>
        </Breadcrumb.Section>
      ) : (
        <Breadcrumb.Section active>
          <span>{I18n.t('sequences.newSequence')}</span>
        </Breadcrumb.Section>
      )}
    </Breadcrumb>
  );
};

export default SequenceBreadcrumb;
