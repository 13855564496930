// Third party libraries
import _ from 'lodash';
import { Checkbox, Icon, Input, SemanticICONS } from 'semantic-ui-react';

// Styled components
import * as S from './styled';
import OButton from '../../styled/button';
import { FlexBox } from '../../styled/wrappers';

// Locales
import I18n from '../../../i18n';

// Interfaces
interface ParamsInterface {
    allSelected?: boolean;
    anySelected?: boolean;
    contentEditable?: boolean;
    editFn?: any;
    editMode?: boolean;
    filterFn?: any;
    filterValue?: string;
    customFilter?: React.ReactNode;
    groupActions?: {
        icon: SemanticICONS;
        label: string;
        callback: () => void;
    }[];
    mainAction: {
        id?: string
        title: string;
        callback: any;
    };
    selectAllFn?: any;
    title: string;
    titleId?: string
};

export default function Submenu({
        allSelected = false,
        anySelected = false,
        contentEditable = false,
        editFn = () => {},
        editMode = false,
        filterFn = () => {},
        filterValue = '',
        customFilter,
        groupActions = [],
        mainAction,
        selectAllFn = () => {},
        title,
        titleId
    }: ParamsInterface) {

    const mobileSubmenu = (
        <div className='mobileSubmenu'>
            { contentEditable ? (
                <FlexBox className='mobileActions' $justifyContent='space-between'>
                    <OButton $upper type='button' $color='var(--brand-secondary)' $primary onClick={ editFn } style={{ paddingLeft: 0 }}>
                        <span>{ I18n.t(editMode ? 'buttons.actions.ok' : 'buttons.actions.edit') }</span>
                    </OButton>
                    <OButton $upper type='button' $color='var(--brand-secondary)' $primary onClick={ editMode ? selectAllFn : mainAction.callback } style={{ paddingRight: 0 }} disabled={ editMode && allSelected } >
                        <span>{ editMode ? I18n.t('buttons.actions.selectAll') : mainAction.title }</span>
                    </OButton>
                </FlexBox>
            ) : (
                <OButton $upper type='button' $color='var(--brand-secondary)' $primary onClick={ mainAction.callback } style={{ float: 'right', paddingRight: 0, top: '5px' }} >
                    <span>{ mainAction.title }</span>
                </OButton>
            )}
            <span className='title'>{ title }</span>
            {
                customFilter ? customFilter : (
                    !_.isFunction(filterFn) ? '' :
                        <div className='filterComponent'>
                            <Input
                                placeholder={ I18n.t('submenu.searchPlaceholder') }
                                type='text'
                                value={ filterValue }
                                onChange={ (e, { value }) => filterFn(value) }
                                icon='search'
                            />
                        </div>
                )
            }
        </div>
    );

    const desktopSubmenu = (
        <div className='desktopSubmenu'>
            <FlexBox $justifyContent='space-between'>
                <span id={ titleId } className='title'>{ title }</span>
                {
                    customFilter ? customFilter : (
                        !_.isFunction(filterFn) ? '' :
                            <div className='filterComponent'>
                                <Input
                                    placeholder={ I18n.t('submenu.searchPlaceholder') }
                                    type='text'
                                    value={ filterValue }
                                    onChange={ (e, { value }) => filterFn(value) }
                                    icon='search'
                                />
                            </div>
                    )
                }
                <OButton $upper type='button' $color='var(--brand-secondary)' $primary onClick={ mainAction.callback } style={{ paddingRight: 0 }}>
                    <span id={mainAction.id}>{ mainAction.title }</span>
                </OButton>
            </FlexBox>
            { contentEditable ?
                <div className='multipleSelectionBar'>
                    <Checkbox checked={ allSelected } onChange={ (e, data) => selectAllFn(data.checked) } />
                    <div className='actions'>
                        { anySelected && groupActions.map((action, index) => <Icon key={ action.icon + '_' + index } name={ action.icon } onClick={ action.callback } />) }
                    </div>
                </div>
                : ''
            }
        </div>
    );

    return (
        <S.SubMenu>
            { mobileSubmenu }
            { desktopSubmenu }
        </S.SubMenu>
    );

}