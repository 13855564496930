// Third Party libraries
import _ from 'lodash';
import { DriveStep } from 'driver.js';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

// Models
import { ProfileInterface } from '../../../models/security';

// Hooks
import useTutorial from './useTutorial';

// Types
type Props = {
  name: string;
  steps: DriveStep[];
};

const TutorialStarter = ({ name, steps }: Props) => {
  const userSession = useSelector(
    (state: { auth: { userSession: ProfileInterface } }) =>
      state.auth.userSession
  );

  const { isDone, startTutorial } = useTutorial(name, steps);

  useEffect(() => {
    if (userSession) {
      if (!isDone && !_.isEmpty(steps)) {
        startTutorial();
      }
    }
  }, [isDone, startTutorial, steps, userSession]);

  return null;
};

export default TutorialStarter;
