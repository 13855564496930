// Third-party libraries
import { useEffect, useState } from 'react';
import { driver, DriveStep } from 'driver.js';

// Hooks
import useCookies from '../useCookies';

export default function useTutorial(name: string, steps?: DriveStep[]) {
  const { setCookie, getCookie } = useCookies();

  const [isDone, setIsDone] = useState(true);

  const driverObj = driver({
    showProgress: true,
    steps,
    onDestroyed: () => {
      setCookie(name, 'done', 365);
      setIsDone(true);
    },
  });

  useEffect(() => {
    const tutorialCookie = getCookie(name);

    setIsDone(tutorialCookie === 'done');
  }, [getCookie, name]);

  const setStepsTutorial = (steps: DriveStep[]) => {
    driverObj.setSteps(steps);
  };

  const startTutorial = () => {
    if(!driverObj.isActive()){
      driverObj.drive();
    }
  };

  return { isDone, setStepsTutorial, startTutorial };
}
