import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import Reducers from './redux-store';

import App from './ui/App';

import reportWebVitals from './reportWebVitals';

import 'semantic-ui-css/semantic.min.css';
import './ui/assets/scss/generic.scss';
import './ui/assets/scss/fonts.scss';
import './ui/assets/scss/typography.scss';
import './ui/assets/scss/grid.scss';
import "driver.js/dist/driver.css";

const store = createStore(
    Reducers,
    composeWithDevTools(applyMiddleware(ReduxThunk))
);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={ store }>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();