import './customToggle.scss'

type Props ={
  checked?: boolean
  disabled?: boolean
  onChange?: (checked: boolean) => void
}

const CustomToggle = ({ checked = false, disabled = false, onChange }: Props) => {
  return (
    <label className="switch">
      <input type="checkbox" defaultChecked={checked} disabled={disabled} onChange={(e)=> !disabled && checked !== e.target.checked && typeof onChange === 'function' && onChange(e.target.checked)} />
      <span className="slider round"></span>
    </label>
  );
};

export default CustomToggle;
