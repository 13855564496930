// Third party libraries
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// Redux
import { closeLoader, openLoader } from '../../../../../redux-store/loader';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

// Models
import StudentModel, { StudentInterface } from '../../../../../models/student';

// Components
import CustomSelect, { FormSelectOptionsInterface } from '../../../../components/form/select';
import { ClosedModal, GenericModalProps } from '../../../../components/genericModal';
import { Form } from 'semantic-ui-react';

// Styled components
import OButton from '../../../../styled/button';

// Locales
import I18n from '../../../../../i18n';

// Styles
import './addStudent.scss'

interface FormInterface {
    studentIds: string[];
}

type CustomProps = {
    groupId: string;
    onChangeModal: (modal: GenericModalProps) => void;
    onForceUpdate: () => void;
    students: StudentInterface[];
};

type Props = CustomProps & InjectedFormProps<FormInterface, CustomProps>;

const AddToGroup: React.FC<Props> = ({ groupId, onChangeModal, onForceUpdate, students, handleSubmit }) => {
    const dispatch = useDispatch();

    const [studentToAssignSelectOptions, setStudentToAssignSelectOptions] = useState<FormSelectOptionsInterface[]>([]);

    useEffect(() => {

        const data: FormSelectOptionsInterface[] = [];
        _.each(students, student => {

            data.push({
                key: student._id,
                text: student.name && student.surname ? `${ student.name } ${ student.surname }` : I18n.t('students.noName'),
                value: student._id
            });

        });

        setStudentToAssignSelectOptions(data);

    }, [groupId, students])

    const handleAddStudentSubmit = async (e: any) => {

        try {
            const studentIds = e.studentIds;

            closeModal();
            dispatch(openLoader());

            await StudentModel.multipleAssignToGroup(studentIds, groupId);

            onForceUpdate();

        } catch (e) {

            dispatch(closeLoader());
            onChangeModal({
                open: true,
                title: I18n.t('buttons.actions.error'),
                renderBody: () => <span>{ I18n.t('messager.errorRecovery') }</span>,
                renderActions: () => <OButton type='button' $color='white' $terciary onClick={ closeModal }><span>{ I18n.t('buttons.actions.understood') }</span></OButton>
            });

        }

    }

    const closeModal = () => onChangeModal(ClosedModal);

    return (
        <Form onSubmit={ handleSubmit(handleAddStudentSubmit) } className='addStudentToGroup'>
            <Form.Field style={{ height: '100%' }}>
                <span style={{ paddingBottom: '10px', display: 'block' }}>
                    <strong>{ I18n.t('groups.chooseStudents') }</strong>
                </span>
                { _.isEmpty(studentToAssignSelectOptions) ?
                    <span style={{ paddingBottom: '10px', display: 'block', color: 'red' }}>
                        <strong>{ I18n.t('groups.emptyStudentList') }</strong>
                    </span>
                    :
                    <Field
                        component={ CustomSelect }
                        name='studentIds'
                        fieldClasses='label-primary'
                        options={ studentToAssignSelectOptions }
                        multiple={ true }
                        search
                    />
                }
                <div className='addStudentsAction'>
                    <OButton fluid type='submit' $color='white' $terciary >
                        <span>{ I18n.t('groups.addStudents') }</span>
                    </OButton>
                </div>
            </Form.Field>
        </Form>
    );
}

export default reduxForm<FormInterface, CustomProps>({
    form: 'addToGroupForm',
})(AddToGroup);