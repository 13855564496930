// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react';

// Redux
import { closeLoader, openLoader } from '../../../redux-store/loader';

// Models
import GroupModel, { GroupInterface } from '../../../models/group';

// Components
import BottomNav from '../../components/bottomNav/BottomNav';
import CustomList, { ItemInterface } from '../../components/list';
import PanelNav from '../../components/nav/PanelNav';
import Submenu from '../../components/nav/Submenu';
import ImageViewer from '../../components/imageViewer';
import TutorialStarter from '../../hooks/tutorials/TutorialStarter';

// Styled components
import { Wrapper } from '../../styled/wrappers';

// Locales
import I18n from '../../../i18n';

// Interfaces
interface MapDispatchProps {
    openLoader: any;
    closeLoader: any;
};

interface GroupsProps extends MapDispatchProps {
    navigate?: any;
};

interface GroupListItem extends GroupInterface {
    selected?: boolean;
}

interface GroupsState {
    filteredGroups: GroupListItem[];
    filterValue: string;
    groups: GroupInterface[];
    groupList: ItemInterface[];
    groupImageSrc: string | null | undefined;
    tutorialSteps: any[],
    loading: boolean
};

// Tutorial constants
const TutorialSteps = [
    { element: '#tutorial-create-group', popover: { title: I18n.t('tutorials.groups.create.title'), description: I18n.t('tutorials.groups.create.description') } },
    { element: '#tutorial-group-list', popover: { title: I18n.t('tutorials.groups.groupList.title'), description: I18n.t('tutorials.groups.groupList.description') } },
]

class Groups extends React.Component<GroupsProps, GroupsState> {

    constructor(props: GroupsProps) {

        super(props);

        this.state = {
            filteredGroups: [],
            filterValue: '',
            groups: [],
            groupList: [],
            groupImageSrc: null,
            tutorialSteps: [],
            loading: false
        };

    }

    componentDidMount() {

        this.setState({ tutorialSteps: TutorialSteps });

        this.getData();

    }

    dataToList = (list: any[], selectable: boolean): ItemInterface[] => {

        const returnedList: ItemInterface[] = [];
        _.each(list, item => {

            returnedList.push({
                _id: item._id,
                selectable,
                selected: !!item.selected,
                pictureUrl: item.imageUrl,
                header: item.name.toUpperCase(),
                description: item.studentsCount + ' ' + I18n.t('menu.students')
            });

        });
        return returnedList;

    };

    getData = async () => {

        this.props.openLoader();

        this.setState({ loading: true });

        const groups = await GroupModel.getGroups();
        const filteredGroups = _.orderBy(groups, 'name', 'desc');

        const groupList = this.dataToList(filteredGroups, false);

        this.setState({ filteredGroups, groups, groupList, loading: false });

        this.props.closeLoader();

    };

    onFilter = (filterValue: string) => {

        let filteredGroups = [ ...this.state.groups ];
        let groupList = [ ...this.state.groupList ];

        if (filterValue) {

            filteredGroups = _.filter(filteredGroups, group => group?.name?.toUpperCase().includes(filterValue.toUpperCase()))
            groupList = this.dataToList(filteredGroups, true);

        }

        this.setState({ filteredGroups, filterValue, groupList })

    }

    render() {

        const { navigate } = this.props;
        const { filterValue, groupList, groupImageSrc, tutorialSteps, loading } = this.state;

        return (
            <Wrapper>
                <PanelNav active='groups' />
                <Container style={{ paddingTop: '20px' }}>
                    <Submenu
                        filterFn={ this.onFilter }
                        filterValue={ filterValue }
                        mainAction={{ id: 'tutorial-create-group', title: I18n.t('groups.newGroup'), callback: () => navigate('/groups/new') }}
                        title={ I18n.t('menu.groups') }
                    />
                    <div className='inner'>
                        <div className='p-flex'>
                            <div className='p-content'>
                                <div className='a-wrapper'>
                                    <div id='tutorial-group-list' className='a-f-wrapper'>
                                        <CustomList
                                            items={ groupList }
                                            onClickItemFn={ (item: ItemInterface) => navigate(`/groups/edit/${ item._id }`) }
                                            onClickItemSpanFn={ (item: ItemInterface)=>  this.setState({ groupImageSrc: item.pictureUrl }) }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <BottomNav active='groups' />
                <ImageViewer open={ groupImageSrc !== null } title={ I18n.t('groups.picture') } src={ groupImageSrc } onClose={ () => this.setState({ groupImageSrc: null }) } />
                {
                    !loading && tutorialSteps && <TutorialStarter name='groups' steps={ tutorialSteps } />
                }
            </Wrapper>
        );

    }

}

export default connect(null, { openLoader, closeLoader })(Groups);