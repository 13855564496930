// Third party libraries
import _ from 'lodash';
import React from 'react';
import { Breadcrumb, Icon, SemanticICONS } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';

// Styled components
import * as S from './styled';
import OButton from '../../styled/button';
import { FlexBox } from '../../styled/wrappers';

// Interfaces
export interface BreadcrumbsInterface {
    key: string | number;
    label: string;
    goTo: string;
};

export interface FnInterface {
    callback: () => void;
    activeOnDesktop?: boolean;
    label?: string;
    icon?: SemanticICONS;
    disabled?: boolean;
};

interface ParamsInterface {
    title: string;
    mainFn: FnInterface;
    secondaryFn?: FnInterface;
    breadcrumbs?: BreadcrumbsInterface[];
};

export default function SecondarySubmenu({ title, mainFn, breadcrumbs, secondaryFn }: ParamsInterface) {

    const navigate = useNavigate();

    const mobileSubmenu = (
        <div className='mobileSubmenu' style={{ marginBottom: 0, position: 'relative', top: '-20px' }}>
            <FlexBox className='mobileActions' $justifyContent='space-between'>
                <OButton type='button' $color='var(--brand-secondary)' $primary onClick={ mainFn.callback } style={{ paddingLeft: 0 }}>
                    { _.isEmpty(mainFn.icon) ? '' : <Icon name={ mainFn.icon } /> }
                    <span>{ mainFn.label || '' }</span>
                </OButton>
                <span style={{ paddingTop: '0.55em', fontSize: '1em' }}><strong>{ title }</strong></span>
                { !!!secondaryFn ? '' :
                    <OButton type='button' $color='var(--brand-secondary)' $primary onClick={ secondaryFn.callback } style={{ paddingRight: 0 }} disabled={ secondaryFn.disabled }>
                        { _.isEmpty(secondaryFn.icon) ? '' : <Icon name={ secondaryFn.icon } /> }
                        <span>{ secondaryFn.label || '' }</span>
                    </OButton>
                }
            </FlexBox>
        </div>
    );

    const desktopSubmenu = (
        <div className='desktopSubmenu secondary' style={{ borderBottom: '1px solid #F4F4F4', position: 'relative', top: '-20px' }}>
            <OButton type='button' $color='var(--brand-secondary)' $primary onClick={ mainFn.callback } style={{ paddingLeft: 0 }}>
                { _.isEmpty(mainFn.icon) ? '' : <Icon name={ mainFn.icon } /> }
                <span>{ mainFn.label || '' }</span>
            </OButton>
            {
                breadcrumbs && breadcrumbs.length > 0  && 
                    <Breadcrumb style={{ marginLeft: '30px' }}>
                        { _.map(breadcrumbs, (breadcrumb, index) => {

                            return (
                                <React.Fragment key={ index }>
                                    <Breadcrumb.Section link={ !_.isEmpty(breadcrumb.goTo) } active={ index === _.size(breadcrumbs) - 1 }>
                                        { !_.isEmpty(breadcrumb.goTo) ? <span onClick={ () => navigate(breadcrumb.goTo) }>{ breadcrumb.label }</span> : breadcrumb.label }
                                    </Breadcrumb.Section>
                                    { index === _.size(breadcrumbs) - 1 ? '' : <Breadcrumb.Divider /> }
                                </React.Fragment>
                            );

                        }) }
                    </Breadcrumb>
            }
            { (!!!secondaryFn || !secondaryFn.activeOnDesktop || !!!secondaryFn.callback) ? '' :
                <OButton type='button' $color='var(--brand-secondary)' $primary onClick={ secondaryFn.callback } style={{ paddingRight: 0, float: 'right' }} disabled={ secondaryFn.disabled }>
                    { _.isEmpty(secondaryFn.icon) ? '' : <Icon name={ secondaryFn.icon } /> }
                    <span>{ secondaryFn.label || '' }</span>
                </OButton>
            }
        </div>
    );

    return (
        <S.SubMenu>
            { mobileSubmenu }
            { desktopSubmenu }
        </S.SubMenu>
    );

}