// Third party libraries
import { useEffect, useState } from 'react';

// Models
import SequenceModel, {
  SequenceFolderInterface,
} from '../../../../../models/sequence';

// Components
import { Icon, List } from 'semantic-ui-react';
import SequenceFolderItem from './SequenceFolderItem';
import OButton from '../../../../styled/button';

// Locales
import I18n from '../../../../../i18n';

type Props = {
  selectedFolderId: string;
  onSelectFolder: (folderId: string) => void;
};

const SequenceFoldersBody = ({ selectedFolderId, onSelectFolder }: Props) => {
  // Esto es ya para abrir un folder, no sólo para seleccionarlo
  const [folderId, setFolderId] = useState('none');

  // Esto es para poder volver atrás
  const [prevFolderId, setPrevFolderId] = useState('none');

  // Esto es para poder mover a la carpeta a la que entré y no solo a las hijas de la que entré
  const [currentFolderId, setCurrentFolderId] = useState('none');

  const [folderList, setFolderList] = useState<SequenceFolderInterface[]>([]);

  const [loading, setLoading] = useState(false);

  const showFolder = (folderId: string) => {
    if (!folderId) return;

    setFolderId((prev) => {
      setPrevFolderId(prev);
      onSelectFolder(folderId);
      return folderId;
    });
  };

  const getData = async (folderId: string) => {
    setLoading(true);

    const { folders, _id, parentId } = await SequenceModel.getSequenceFolder(
      folderId
    );
    setFolderList(folders);

    if (_id) {
      setCurrentFolderId(_id);
    } else{
      setCurrentFolderId('none');
    }

    if (parentId) {
      setPrevFolderId(parentId);
    } else{
      setPrevFolderId('none');
    }

    setLoading(false);
  };

  useEffect(() => {
    getData(folderId);
  }, [folderId]);

  const handleSelectFolder = (folderId: string) => {
    // Si vuelvo a seleccionar la misma carpeta, la deselecciono para seleccionar la carpeta padre
    if (folderId === selectedFolderId) {
      onSelectFolder(currentFolderId);
      return;
    }
    onSelectFolder(folderId);
  };

  return (
    <div>
      <header>
        <OButton
          type="button"
          $color="black"
          $primary
          style={{ padding: 0, paddingLeft: '0.5rem', cursor: 'pointer' }}
          onClick={() => showFolder(prevFolderId)}
        >
          <Icon name="arrow circle left" size="large" />
        </OButton>
      </header>
      <List className="sequenceFoldersModalList" selection>
        {loading ? (
          <span className="labeling">{I18n.t('loaders.loading')}...</span>
        ) : folderList && folderList.length > 0 ? (
          folderList.map((folder) => (
            <SequenceFolderItem
              key={folder._id}
              folder={folder}
              isSelected={selectedFolderId === folder._id}
              onShowFolder={showFolder}
              onSelectFolder={handleSelectFolder}
            />
          ))
        ) : (
          <span className="labeling">{I18n.t('sequences.noFolders')}</span>
        )}
      </List>
    </div>
  );
};

export default SequenceFoldersBody;
